import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomCombineTypes,
  ExamPeriodCourseTermLectureLocations,
  Grades,
  ExamTypes,
  ExamEnvironments
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import MultiSelectionTable from '../exam-period/multi-selection-table';
import { CopyCourseFormVal } from './validations/copy-course-form-val';

const T = Translator.create();
const equal = require('deep-equal');
const Logger = Log.create('CopyCourseModal');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;

let faculty_ids_selected: Array<any> = [];
let program_ids_selected: Array<any> = [];
let class_ids_selected: Array<any> = [];
let section_ids_selected: Array<any> = [];
let lecture_staff_ids_selected: Array<any> = [];
let faculties_selected: Array<any> = [];
let programs_selected: Array<any> = [];
let classes_selected: Array<any> = [];
let sections_selected: Array<any> = [];
let lecture_staff_selected: Array<any> = [];

let faculty_ids_labels: Array<any> = [];
let faculties_labels: Array<any> = [];
let program_ids_labels: Array<any> = [];
let programs_labels: Array<any> = [];
let class_ids_labels: Array<any> = [];
let classes_labels: Array<any> = [];
let section_ids_labels: Array<any> = [];
let sections_labels: Array<any> = [];
let lecture_staff_ids_labels: Array<any> = [];
let lecture_staff_labels: Array<any> = [];
let student_count_labels: Array<any> = new Array<any>();

class CopyCourseModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1
    },
    isAdded: false,
    alreadyInTheList: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    faculty_ids_labels.splice(0, faculty_ids_labels.length);
    faculties_labels.splice(0, faculties_labels.length);
    program_ids_labels.splice(0, program_ids_labels.length);
    programs_labels.splice(0, programs_labels.length);
    class_ids_labels.splice(0, class_ids_labels.length);
    classes_labels.splice(0, classes_labels.length);
    section_ids_labels.splice(0, section_ids_labels.length);
    sections_labels.splice(0, sections_labels.length);
    lecture_staff_ids_labels.splice(0, lecture_staff_ids_labels.length);
    lecture_staff_labels.splice(0, lecture_staff_labels.length);
    student_count_labels.splice(0, student_count_labels.length);

    faculty_ids_selected.splice(0, faculty_ids_selected.length);
    program_ids_selected.splice(0, program_ids_selected.length);
    class_ids_selected.splice(0, class_ids_selected.length);
    section_ids_selected.splice(0, section_ids_selected.length);
    lecture_staff_ids_selected.splice(0, lecture_staff_ids_selected.length);
    faculties_selected.splice(0, faculties_selected.length);
    programs_selected.splice(0, programs_selected.length);
    classes_selected.splice(0, classes_selected.length);
    sections_selected.splice(0, sections_selected.length);
    lecture_staff_selected.splice(0, lecture_staff_selected.length);
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    faculty_ids_labels.length == 0 && plusButtonClicked == false ? faculty_ids_labels = values.faculty_ids : faculty_ids_labels = faculty_ids_labels;
    faculty_ids_labels = values.faculty_id != undefined && plusButtonClicked ? faculty_ids_labels.concat(values.faculty_id) : faculty_ids_labels;
    faculties_labels = values.faculty_id != undefined && plusButtonClicked ? faculties_labels.concat(values.faculties) : values.faculties;

    program_ids_labels.length == 0 && plusButtonClicked == false ? program_ids_labels = values.program_ids : program_ids_labels = program_ids_labels;
    program_ids_labels = values.program_id != undefined && plusButtonClicked ? program_ids_labels.concat(values.program_id) : program_ids_labels;
    programs_labels = values.program_id != undefined && plusButtonClicked ? programs_labels.concat(values.programs) : values.programs;

    lecture_staff_ids_labels.length == 0 && plusButtonClicked == false ? lecture_staff_ids_labels = values.instructor_ids : lecture_staff_ids_labels = lecture_staff_ids_labels;
    lecture_staff_ids_labels = values.instructor_id != undefined && plusButtonClicked ? lecture_staff_ids_labels.concat(values.instructor_id) : lecture_staff_ids_labels;
    lecture_staff_labels = values.instructor_id != undefined && plusButtonClicked ? lecture_staff_labels.concat(values.instructors) : values.instructors;

    classes_labels = classes_labels.concat(values.classes);

    if (values.classes.length == 1 && class_ids_labels.length == 0) {
      class_ids_labels = class_ids_labels.concat(values.classes.value);
    } else if (class_ids_labels.length == 0 && values.classes.length > 1) {
      class_ids_labels = values.classes && values.classes.map((item: any) => item.value);
    } else if (class_ids_labels.length == 0 && values.classes.length == 1) {
      class_ids_labels = values.classes && values.classes.value;
    }

    if (values.class_id != undefined && plusButtonClicked) {
      class_ids_labels = class_ids_labels.concat(values.class_id);
    } else {
      class_ids_labels = class_ids_labels;
    }

    if ((section_ids_labels.length == 0 || sections_labels.length == 0) && plusButtonClicked == false) {
      section_ids_labels = values.sections;
      sections_labels = values.sections;
    }

    if (values.section != undefined && values.section != '' && plusButtonClicked) {
      section_ids_labels = section_ids_labels.concat(values.section);
      sections_labels = sections_labels.concat(values.section);
    } else if ((values.section == undefined || values.section == '') && plusButtonClicked) {
      section_ids_labels = section_ids_labels.concat('1');
      sections_labels = sections_labels.concat('1');
      values.section = '1'
    }

    student_count_labels.length == 0 && plusButtonClicked == false ? student_count_labels = values.student_counts : student_count_labels = student_count_labels;
    student_count_labels = values.student_count != undefined && plusButtonClicked ? student_count_labels.concat(values.student_count) : student_count_labels;

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = false;
  };

  multiSelecteds(
    faculty_ids: any, program_ids: any, class_ids: any, section_ids: any, lecture_staff_ids: any,
    faculties: any, programs: any, classes: any, sections: any, lecture_staff: any, student_counts: any
  ) {
    multiSelectedRow = true;
    faculty_ids_selected = faculty_ids;
    program_ids_selected = program_ids;
    class_ids_selected = class_ids;
    section_ids_selected = section_ids;
    lecture_staff_ids_selected = lecture_staff_ids;

    faculties_selected = faculties;
    programs_selected = programs;
    classes_selected = classes;
    sections_selected = sections;
    lecture_staff_selected = lecture_staff;

    faculty_ids_labels = faculty_ids;
    faculties_labels = faculties;
    program_ids_labels = program_ids;
    programs_labels = programs;
    class_ids_labels = class_ids;
    classes_labels = classes;
    section_ids_labels = section_ids;
    sections_labels = sections;
    lecture_staff_ids_labels = lecture_staff_ids;
    lecture_staff_labels = lecture_staff;
    student_count_labels = student_counts;
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onFormSave = (model: Types.ICopyCourseFormValues, FormActions: FormikActions<Types.ICopyCourseFormValues>) => {
    if (saveButtonClicked) {
      let newModel;
      let course_programs: any;

      course_programs = faculty_ids_selected.map((faculty_id: any, index: any) => (
        {
          faculty_id: faculty_id,
          campus_id: model.campus_id,
          program_id: program_ids_selected[index],
          class: class_ids_selected[index],
          instructor_id: lecture_staff_ids_selected[index],
          section: section_ids_selected[index],
          student_count: student_count_labels[index]
        }));

      if (course_programs == undefined || course_programs == [] || course_programs.length == 0) {
        this.props.dispatch(Actions.Notification(T.t("gen_list_isnot_left_blank"), 'gen_warning', 'danger'))
      } else {
        if (multiSelectedRow) {
          newModel = {
            assistant_staff: model.assistant_staff,
            assistant_staff_ids: model.assistant_staff_ids,
            campus: model.campus,
            campus_id: model.campus_id,
            classroom_combine_status: model.classroom_combine_status,
            conjugate_course_code: model.conjugate_course_code,
            course_code: model.course_code,
            description: model.description,
            exam_duration: model.exam_duration,
            exam_session: model.exam_session,
            course_programs: course_programs,
            invigilator_gap_after_exam: model.invigilator_gap_after_exam,
            invigilator_gap_before_exam: model.invigilator_gap_before_exam,
            name: model.name,
            student_counts: student_count_labels,
            term_id: model.term_id
          }
          multiSelectedRow = false;
        }

        let courseInfo: any = {};

        let courseArray: { id: number; item: Types.IExamPeriod_CourseItem } = this.props.course[0];
        if (courseArray && courseArray.item && courseArray.item.courseInfo) {
          courseInfo = { ...courseArray.item.courseInfo, ...newModel, term_id: this.state.term_id };
        }
        if (courseInfo && Object.keys(courseInfo).length) {
          courseInfo = Object.keys(courseInfo).reduce((object: any, key: string) => {
            if (key !== 'course_id') {
              object[key] = courseInfo[key];
            }
            return object;
          }, {});
        }

        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_activity_copy'),
            body: T.t('gen_copy_activity_question'),
            name: 'examPeriod_courses_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (status: number) => {
                if (status === 200 || status === 201) {
                  this.setCloseModal();
                  this.onUpdateListFromModal();
                }
              };

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_COPY_COURSE_TO_PERIOD,
                  courseInfo,
                  'ep-copy-course',
                  resultCallback
                )
              );
            }
          })
        );

        saveButtonClicked = false;
        FormActions.setSubmitting(false);
      }
    }
  };

  static getDerivedStateFromProps(props: any, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (state.combineExamsModalIsOpen && !props.modalIsOpen) {
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  createFieldValues = (): Types.ICopyCourseFormValues => {
    let fields: Types.ICopyCourseFormValues = {
      course_code: '',
      conjugate_course_code: '',
      name: '',
      student_counts: [],
      instructor_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty: undefined,
      exam_duration: undefined,
      class: undefined,
      section: '',
      section_ids: [],
      sections: [],
      exam_session: undefined,
      description: '',
      instructor_id: 0,
      class_id: 0,
      program_id: 0,
      faculty_id: 0,
    };

    if (this.props.course && this.props.course.length) {
      let courseArray: { id: number; item: Types.IExamPeriod_CourseItem } = this.props.course[0];

      if (courseArray && courseArray.item && courseArray.item.courseInfo) {
        let courseInfo = courseArray.item.courseInfo;

        const _student_count = courseInfo.student_counts ? courseInfo.student_counts : [];
        const student_count = _student_count.reduce((a, b) => a + b, 0);

        fields = {
          course_code: courseInfo.course_code ? courseInfo.course_code : '',
          conjugate_course_code: courseInfo.conjugate_course_code ? courseInfo.conjugate_course_code : '',
          name: courseInfo.name ? courseInfo.name : '',
          campus: courseInfo.campuses && courseInfo.campuses.every((val, i, arr) => val.value === arr[0].value) ? courseInfo.campuses[0] : undefined,
          campus_id: courseInfo.campuses && courseInfo.campuses.every((val, i, arr) => val.value === arr[0].value) ? courseInfo.campuses[0].value : undefined,
          faculties: courseInfo.faculties ? courseInfo.faculties : undefined,
          faculty_ids: courseInfo.faculty_ids ? courseInfo.faculty_ids : undefined,
          student_count_total: student_count,
          student_counts: courseInfo && courseInfo.student_counts ? courseInfo.student_counts : [],
          invigilator_gap_before_exam: courseInfo.invigilator_gap_before_exam ? courseInfo.invigilator_gap_before_exam : 0,
          invigilator_gap_after_exam: courseInfo.invigilator_gap_after_exam ? courseInfo.invigilator_gap_after_exam : 0,
          instructors: courseInfo.instructors ? courseInfo.instructors : [],
          instructor_ids: courseInfo.instructor_ids ? courseInfo.instructor_ids : [],
          assistant_staff: courseInfo.assistant_staff ? courseInfo.assistant_staff : [],
          assistant_staff_ids: courseInfo.assistant_staff_ids ? courseInfo.assistant_staff_ids : [],
          programs: courseInfo.programs ? courseInfo.programs : [],
          program_ids: courseInfo.program_ids ? courseInfo.program_ids : [],
          class: courseInfo.class ? courseInfo.class : undefined,
          classes: courseInfo.classes ? courseInfo.classes : undefined,
          sections: courseInfo.sections ? courseInfo.sections : undefined,
          exam_duration: courseInfo.exam_duration ? courseInfo.exam_duration : undefined,
          exam_session: courseInfo.exam_session ? courseInfo.exam_session : undefined,
          exam_environment: courseInfo.exam_environment,
          exam_type: courseInfo.exam_type,
          classroom_combine_status: courseInfo.classroom_combine_status,
          description: courseInfo.description ? courseInfo.description : undefined,
        };
      }
    }
    return fields;
  };


  render() {
    const formInitialValues = this.createFieldValues();

    if (faculty_ids_labels.length == 0 || faculty_ids_labels == undefined) {
      this.putToTable(formInitialValues, false);
    }

    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_copy_exam')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validationSchema={CopyCourseFormVal(T)}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.ICopyCourseFormValues>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;

                      let table_values = faculty_ids_labels && faculty_ids_labels.map((faculty_id_label: any, index: any) => (
                        {
                          values:
                            faculty_ids_labels![index] + ',' +
                            program_ids_labels![index] + ',' +
                            class_ids_labels![index] + ',' +
                            section_ids_labels![index] + ',' +
                            lecture_staff_ids_labels![index] + ',' +
                            student_count_labels![index]
                        }))

                      let current_form_values = { values: values.faculty_id + ',' + values.program_id + ',' + values.class_id + ',' + values.section + ',' + values.instructor_id + ',' + values.student_count }
                      if (table_values!.some((e: { values: string; }) => e.values == current_form_values.values)) {
                        this.state.alreadyInTheList = true;
                        this.state.isAdded = true;
                      } else if (this.state.isAdded) {
                        this.state.alreadyInTheList = false;
                        this.state.isAdded = false;
                      } else {
                        this.state.alreadyInTheList = false;
                        this.state.isAdded = false;
                      }

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="course_code"
                                name="course_code"
                                value={values.course_code || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="course_code">
                                {T.t('gen_code')}
                              </label>
                              {errors && errors.course_code && props.submitCount > 0 && (
                                <div className="error">{errors && errors.course_code}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">{T.t("gen_name")}</label>
                              {errors && errors.name && props.submitCount > 0 && (
                                <div className="error">{errors && errors.name}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="conjugate_course_code"
                                name="conjugate_course_code"
                                value={values.conjugate_course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="student_count_total"
                                name="student_count_total"
                                value={values.student_count_total}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                disabled={true}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="student_count_total">{T.t('gen_total_student_count')}</label>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_duration"
                                name="exam_duration"
                                value={values.exam_duration || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_duration">{T.t('gen_exam_duration')}</label>
                              {errors && errors.exam_duration && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_duration}</div>
                              )}
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_session"
                                name="exam_session"
                                value={values.exam_session || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                              {errors && errors.exam_session && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_session}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_before_exam"
                                name="invigilator_gap_before_exam"
                                value={values.invigilator_gap_before_exam || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_before_exam">{T.t('gen_invigilator_gap_before_exam')}</label>
                              {errors && errors.invigilator_gap_before_exam && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_gap_before_exam}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_after_exam"
                                name="invigilator_gap_after_exam"
                                value={values.invigilator_gap_after_exam || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_after_exam">{T.t('gen_invigilator_gap_after_exam')}</label>
                              {errors && errors.invigilator_gap_after_exam && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_gap_after_exam}</div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_campus')}</label>
                                  <Select
                                    id='select_campus'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={CampusSelectOptions}
                                    placeholder={T.t('gen_select_location')}
                                    value={props.values.campus ? props.values.campus : null}
                                    onChange={(option: any) => {
                                      props.setFieldValue('campus', option);
                                      props.setFieldValue('campus_id', option && option.value);
                                      if (option.value == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                        values.exam_environment = ExamEnvironments(T)[1].value;
                                        values.exam_environment_select = ExamEnvironments(T)[1];
                                      } else {
                                        if (values.exam_environment == ExamEnvironments(T)[1].value) {
                                          values.exam_environment = undefined;
                                          values.exam_environment_select = undefined;
                                        }
                                      }
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>
                                {errors && errors.campus_id && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.campus_id}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_type')}</label>
                                  <Select
                                    id='select_exam_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamTypes(T)}
                                    placeholder={T.t('gen_select_exam_type')}
                                    value={
                                      values.exam_type != undefined
                                        ? ExamTypes(T).find(
                                          (option) => option.value === values.exam_type
                                        )
                                        : null
                                    }
                                    onChange={(option: any) =>
                                      props.setFieldValue('exam_type', option.value)
                                    }
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_exam_environment')}</label>
                                  <Select
                                    id='select_exam_environmet'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ExamEnvironments(T)}
                                    placeholder={T.t('gen_select_exam_environment')}
                                    value={
                                      values.exam_environment != undefined
                                        ? ExamEnvironments(T).find(
                                          (option) => option.value === values.exam_environment
                                        )
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('exam_environment', option.value)
                                      props.setFieldValue('exam_environment_select', option);
                                      if (option.value == ExamEnvironments(T)[1].value) {
                                        values.campus_id = ExamPeriodCourseTermLectureLocations(T)[0].value;
                                        values.campus = ExamPeriodCourseTermLectureLocations(T)[0];
                                      } else {
                                        if (values.campus_id == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                          values.campus_id = undefined;
                                          values.campus = undefined;
                                        }
                                      }
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_classroom_combine_status')}</label>
                                  <Select
                                    id='select_status'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ClassroomCombineTypes(T)}
                                    placeholder={T.t('gen_select_status')}
                                    value={
                                      values.classroom_combine_status != undefined
                                        ? ClassroomCombineTypes(T).find(
                                          (option) => option.value === values.classroom_combine_status
                                        )
                                        : null
                                    }
                                    onChange={(option: any) =>
                                      props.setFieldValue('classroom_combine_status', option.value)
                                    }
                                    noOptionsMessage={(): string => T.t('gen_select_no_status')}
                                  />
                                </div>
                                {errors && errors.classroom_combine_status && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.classroom_combine_status}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_assistants')}</label>
                                  <Select
                                    id='select_assistant'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_assistant')}
                                    value={values.assistant_staff}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('assistant_staff', list);
                                      props.setFieldValue(
                                        'assistant_staff_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                  />
                                </div>
                                {errors && errors.assistant_staff && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.assistant_staff}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 form-input form-group with-icon mt-3">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_faculty_college_institute')}</label>
                                  <Select
                                    id='select_faculty'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.faculties
                                        ? this.props.selectOptions.faculties
                                        : []
                                    }
                                    placeholder={T.t('gen_select_faculty_college_institute')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('faculty_id', option && option.value);
                                      props.setFieldValue('faculties', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_program_department')}</label>
                                  <Select
                                    id='select_program'
                                    className="react-select"
                                    isDisabled={values.faculty_id == undefined || values.faculty_id == 0 ? true : false}
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.programs
                                        ? this.props.selectOptions.programs
                                        : []
                                    }
                                    placeholder={T.t('gen_select_program_department')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('program_id', option && option.value);
                                      props.setFieldValue('programs', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_grade')}</label>
                                  <Select
                                    id='select_grade'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    isDisabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined ? true : false}
                                    closeMenuOnSelect={true}
                                    options={Grades}
                                    placeholder={T.t('gen_select_grade')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('class_id', option && option.value);
                                      props.setFieldValue('classes', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 form-input form-group with-icon mt-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <input
                                    id="section"
                                    name="section"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == 0 || values.class_id == undefined ? true : false}
                                    type="text"
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="gen_section">{T.t('gen_section')}</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_instructors')}</label>
                                  <Select
                                    id='select_instructor'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    isDisabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == undefined ? true : false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_instructor')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('instructor_id', option && option.value);
                                      props.setFieldValue('instructors', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 form-input form-group with-icon">
                              <input
                                id="student_count"
                                name="student_count"
                                value={values.student_count}
                                disabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == undefined || values.instructor_id == 0 || values.instructor_id == undefined ? true : false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="student_count">{T.t('gen_number_students_will_take_exam')}</label>
                              {(values.instructor_id != 0 && values.instructor_id != undefined && values.student_count != undefined && values.student_count <= 0) ? (
                                <div className="error">{T.t('gen_must_be_greater_than_zero')}</div>
                              ) : null}
                            </div>
                            <div className="col-md text-md-right mb-3">
                              <button
                                id='button_add_circle_outline'
                                type="button"
                                disabled={
                                  ((values.student_count != undefined && values.student_count != 0) ? false : true)
                                  || (this.state.alreadyInTheList ? true : false)
                                }
                                data-dismiss="modal"
                                onClick={
                                  this.state.alreadyInTheList ?
                                    () => { } :
                                    () => { this.putToTable(values, true) }
                                }
                                className="btn btn-green"
                                aria-label="alert-success"
                              >
                                <i className="material-icons mr-2">add_circle_outline</i>
                              </button>
                            </div>
                            {/* <div className="col-md-12 list-error">
                              {
                                this.state.alreadyInTheList && this.state.isAdded ?
                                  <span>Listede zaten eklidir. Lütfen farkı bilgiler giriniz.</span>
                                  : null
                              }
                            </div> */}
                            <div className="col-md-12 list-error">
                              {
                                faculty_ids_labels.length == 0 || program_ids_labels.length == 0 || class_ids_labels.length == 0 || lecture_staff_ids_labels.length == 0 ?
                                  <span>{T.t("gen_info_cannot_leave_empty")}</span>
                                  : null
                              }
                            </div>
                            <MultiSelectionTable
                              faculty_ids_labels={faculty_ids_labels}
                              faculties_labels={faculties_labels}
                              program_ids_labels={program_ids_labels}
                              programs_labels={programs_labels}
                              class_ids_labels={class_ids_labels}
                              classes_labels={classes_labels}
                              section_ids_labels={section_ids_labels}
                              sections_labels={sections_labels}
                              lecture_staff_ids_labels={lecture_staff_ids_labels}
                              lecture_staff_labels={lecture_staff_labels}
                              student_count_labels={student_count_labels}
                              multiSelectionObjectFunction={this.multiSelecteds}
                            />
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                id='button_save'
                                type="button"
                                disabled={
                                  (values.name == '' || values.name == null || values.course_code == '' || values.course_code == null || values.exam_duration == 0 || values.exam_duration == null || values.exam_session == 0 || values.exam_session == null ? true : false)
                                }
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => {
                                  props.handleSubmit()
                                  saveButtonClicked = true
                                }}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <h6>{T.t('gen_copied_activity')}</h6>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_course_no')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_code')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_conjugate_course_code')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_name')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_student_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_grade')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_section')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_faculty')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_campus')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_duration')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_session_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_classroom_combine_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_type')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_environment')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_connected_programs')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_instructors')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_assistants')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_description')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.course &&
                        this.props.course.length ?
                        this.props.course.map((courseObject: { id: number; item: Types.IExamPeriod_CourseItem }, index: any) => {

                          let courseItem = courseObject.item.courseInfo;

                          let ClassroomCombineStatusObject: any = {};
                          let combineStatus = courseItem && courseItem.classroom_combine_status;
                          ClassroomCombineStatusObject = ClassroomCombineTypes(T).find(
                            (object) => object.value == combineStatus
                          );

                          let examType = courseItem && courseItem.exam_type;
                          let ExamTypesObject = ExamTypes(T).find(
                            (object) => object.value == examType
                          );

                          let examEnvironment = courseItem && courseItem.exam_environment;
                          let ExamEnvironmentsObject = ExamEnvironments(T).find(
                            (object) => object.value == examEnvironment
                          );

                          return (
                            <tr
                              key={index}
                              data-title={courseItem && courseItem.name}
                            >
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='status'
                                    className={
                                      `small-tag text-uppercase` +
                                      (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(courseItem && courseItem.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                                {courseItem && courseItem.course_id}
                              </td>
                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.course_code ? courseItem.course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.conjugate_course_code ? courseItem.conjugate_course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{courseItem && courseItem.name ? courseItem.name.split(';').join(', ') : '-'}</div>
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.student_counts && courseItem.student_counts.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.student_counts && courseItem.student_counts.map((i: any, index: any) => (index == courseItem!.student_counts!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.classes && courseItem.classes.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.classes && courseItem.classes.map((i: any, index: any) => (index == courseItem!.classes!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.sections && courseItem.sections.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.sections && courseItem.sections.map((i: any, index: any) => (index == courseItem!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.faculties && courseItem.faculties.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.faculties && courseItem.faculties.map((i: any, index: any) => (index == courseItem!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.campuses && courseItem.campuses.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.campuses && courseItem.campuses.map((i: any, index: any) => (index == courseItem!.campuses!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                                {courseItem && courseItem.exam_duration}
                              </td>
                              <td data-label={T.t('gen_session_count')} className="text-center">
                                {courseItem && courseItem.exam_session}
                              </td>
                              <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                                {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label}
                              </td>
                              <td data-label={T.t('gen_exam_environment')} className="text-center">
                                {ExamTypesObject && ExamTypesObject.label ? ExamTypesObject && ExamTypesObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                                {ExamEnvironmentsObject && ExamEnvironmentsObject.label ? ExamEnvironmentsObject && ExamEnvironmentsObject.label : '-'}
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.programs && courseItem.programs.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.programs && courseItem.programs.map((i: any, index: any) => (index == courseItem!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.instructors && courseItem.instructors.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem.instructors && courseItem.instructors.map((i: any, index: any) => (index == courseItem!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  courseItem && courseItem.assistant_staff && courseItem.assistant_staff.length > 0 ?
                                    <div className="table-scrollable-td">{courseItem && courseItem.assistant_staff && courseItem.assistant_staff.map((i: any, index: any) => (index == courseItem!.assistant_staff!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {courseItem && courseItem.description ? courseItem.description : '-'}
                              </td>
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    // results: store.state.course_page && store.state.course_page.results,
    // filters: store.state.course_page && store.state.course_page.filters,
    // all_ids: store.state.course_page && store.state.course_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.coursePage
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
  // if (next.state.course_page) {
  //   return (
  //     !!equal(
  //       prev.state.course_page && prev.state.course_page.results,
  //       next.state.course_page && next.state.course_page.results
  //     ) &&
  //     !!equal(
  //       prev.state.select_options && prev.state.select_options.coursePage,
  //       next.state.select_options && next.state.select_options.coursePage
  //     )
  //   );
  // } else {
  //   return true;
  // }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CopyCourseModal);

export default container;

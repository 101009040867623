import * as Types from '../types';
import Translator from '../../services/translate-factory';

export const constants = {
  COURSE_LIST_SEARCH: 'COURSE:LIST_SEARCH',
  COURSE_CREATE: 'COURSE:CREATE',
  COURSE_UPDATE: 'COURSE:UPDATE',
  COURSE_DELETE: 'COURSE:DELETE',
  COURSE_GET_BY_ID: 'COURSE:GET_BY_ID',
  COURSE_GET_SELECT_OPTIONS: 'COURSE:GET_SELECT_OPTIONS',
  COURSE_CREATE_EXCEL_BULK: 'COURSE:CREATE_EXCEL_BULK',
  COURSE_CHANGE_ALL_DATA: 'COURSE:CHANGE_ALL_DATA',
  COURSE_GET_PROGRAMS_BY_FACULTIES: 'COURSE:GET_PROGRAMS_BY_FACULTIES'
};

export const CourseSearchInitialValues: Types.IFilterCourse = {
  course_ids_select: [],
  course_code: '',
  name: '',
  status: [],
  term_id: undefined,
  add_list: [],
  select_all: false,
  campuses: [],
  campus_ids: undefined,
  education_types_select: [],
  lecture_staff: [],
  lecture_staff_ids: undefined,
  instructors: [],
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const CourseHoursSearchInitialValues: Types.IFilterCourse = {
  course_ids_select: [],
  course_code: '',
  name: '',
  status: [1],
  term_id: undefined,
  add_list: [],
  select_all: false,
  campuses: [],
  campus_ids: undefined,
  education_types_select: [],
  classroom_combine_status: [],
  classroom_combine_status_ids: undefined,
  instructors: [],
  lecture_staff: [],
  lecture_staff_ids: undefined,
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const CourseFormInitialValues: Types.ICourseItem = {
  course_id: undefined,
  course_code: '',
  conjugate_course_code: '',
  status: 1,
  class: undefined,
  name: '',
  student_count: undefined,
  section: '',
  activity_type: undefined,
  education_type: undefined,
  lesson_count: undefined,
  week: undefined,
  exam_duration: undefined,
  lecture_duration: undefined,
  exam_session: undefined,
  invigilator_gap_after_exam: undefined,
  invigilator_gap_before_exam: undefined,
  practical_duration: undefined,
  term: undefined,
  assistant_staff: [],
  campuses: [],
  faculties: [],
  programs: [],
  sections: [],
  lecture_staff: [],
  faculty_id: undefined
};

export const CombineExamsDataModel: Types.ICombineExamsDataModel = {
  term_id: -1,
  combined_courses: [],
  formValues: {
    course_code: '',
    conjugate_course_code: '',
    name: '',
    student_count: -1,
    lecture_staff: [],
    lecture_staff_ids: [],
    assistant_staff: [],
    assistant_staff_ids: [],
    programs: [],
    program_ids: [],
    campus: undefined,
    campus_id: 0,
    faculty: undefined,
    faculty_id: 0,
    exam_duration: undefined,
    class: undefined,
    section: '',
    exam_session: undefined,
    classroom_combine_status: -1,
    description: ''
  }
};

export const ExamTypes = (T: Translator) => [
  { label: T.t('gen_multiple_choice_classical_open_ended_written'), value: 1 },
  { label: T.t('gen_assigment_project_report_submission'), value: 2 },
  { label: T.t('gen_application'), value: 3 },
  { label: T.t('gen_jury_defense'), value: 4 }
];

export const ExamEnvironments = (T: Translator) => [
  { label: T.t('gen_face_to_face'), value: 1 },
  { label: T.t('gen_online_exam'), value: 2 }
];

export const TermTypes = (T: Translator) => [
  { label: T.t('gen_term_fall'), value: 1 },
  { label: T.t('gen_term_spring'), value: 2 },
  { label: T.t('gen_term_summer'), value: 3 }
];

export const ViewOptions: Array<Types.ISelectOption> = [
  { label: 'Sınav Planlama', value: 1 },
  { label: 'Ders Planlama', value: 2 },
  { label: 'Tüm Alanlar', value: 3 }
];

export const ClassroomCombineTypes = (T: Translator) => [
  { label: T.t('gen_combined'), value: 0 },
  { label: T.t('gen_according_to_instructors'), value: 1 },
  { label: T.t('gen_by_programs'), value: 2 }
];

export const CourseHourStatusTypes = (T: Translator) => [
  { label: T.t('gen_badge_unassigned'), value: 0 },
  { label: T.t('gen_badge_assigned'), value: 1 }
];

export const EducationTypeSelectOptions = (T: Translator) => [
  { label: T.t('gen_formal_education'), value: 'NO' },
  { label: T.t('gen_secondary_education'), value: 'IO' },
  { label: T.t('gen_distance_learning'), value: 'Uzaktan Eğitim' }
];

export const CourseTypes: Array<Types.ISelectOption> = [
  { label: 'gen_compulsory', value: 'Z' },
  { label: 'gen_optional', value: 'S' }
];

export const CourseTermLectureLocations = (T: Translator) => [
  { label: T.t('gen_external_responsible'), value: 1000 },
  { label: T.t('gen_external_active'), value: 1001 },
  { label: T.t('gen_distance_learning'), value: 1002 }
];

export const ExamPeriodCourseTermLectureLocations = (T: Translator) => [
  { label: T.t('gen_remote_exam'), value: 1003 }
];

export const Grades = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_course_number'), value: 'COURSE_ID' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];

export const ImportingKeyActivitySelectOptions = (T: Translator) => [
  { label: T.t('gen_activity_number'), value: 'ACTIVITY_NO' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];